/* eslint-disable camelcase, no-undef, no-unused-vars, class-methods-use-this */
import '../../scss/base.scss';
import '../../scss/components/uppy.scss';
import '@fancyapps/fancybox/dist/jquery.fancybox.css';
import '../../scss/select2-bootstrap.scss';
import 'flatpickr/dist/flatpickr.css';

import Swal from 'sweetalert2';
import * as Bootstrap from 'bootstrap';

import Highcharts from 'highcharts';
import accessibility from 'highcharts/modules/accessibility';
import exporting from 'highcharts/modules/exporting';
import exportdata from 'highcharts/modules/export-data';
import initMore from 'highcharts/highcharts-more';
import solidgauge from 'highcharts/modules/solid-gauge';
import drilldown from 'highcharts/modules/drilldown';
import mapInit from 'highcharts/modules/map';

import '@fancyapps/fancybox/dist/jquery.fancybox.min.js';
import '@popperjs/core/dist/umd/popper.min.js';

import {humanDate} from '@canopyllc/roots/utils/dates.js';
import {snackbar} from '../utils/sweetAlert2.js';
import Themes from './themes.js';
import {
  BaseAPI, BaseFilters, BaseUtils, BootstrapDependencies, Nav,
} from './utils.js';
import './icons.js';

// setup highcharts
initMore(Highcharts);
accessibility(Highcharts);
exporting(Highcharts);
exportdata(Highcharts);
solidgauge(Highcharts);
drilldown(Highcharts);
mapInit(Highcharts);

window.Highcharts = Highcharts;

class BaseJS {
  constructor() {
    // es6 exports
    this.swal = Swal;
    this.bootstrap = Bootstrap;
    this.highcharts = Highcharts;

    this.snackbar = snackbar;
    this.humanDate = humanDate;
  }
}
window.base_js = new BaseJS();

new Nav();

window.themes = Themes;

window.baseFilters = new BaseFilters();

window.bootstrapDependencies = new BootstrapDependencies();

window.baseAPI = new BaseAPI();

window.baseUtils = new BaseUtils();

/**
 * Set module options
 * Leaving this here for now because I don't want to refactor all the usages. It should probably be re-thought anyway.
 *
 * @param {object} base_obj Is the base object that all options will be merged into.
 * @param {object} defaultOptions The default options used in the module.
 * @param {object} options The options passed to the module when initialized.
 * @param {array} required_options An array of options that are required for the module to work.
 */
window.SetModuleOptions = function(base_obj, defaultOptions, options, required_options = []) {
  this.defaultOptions = defaultOptions;
  this.options = options;
  this.required_options = required_options;
  this.check_required_options();
  this.remove_undefined_options();
  $.extend(base_obj, this.defaultOptions, this.options);
};
window.SetModuleOptions.prototype = {
  check_required_options() {
    let self = this;

    $.each(this.required_options, (i, k) => {
      if (self.options[k] === undefined) {
        console.error(`The ${k} option is required`); // eslint-disable-line no-console
      }
    });
  },
  remove_undefined_options() {
    let self = this;

    $.each(self.options, (k, v) => {
      if (self.defaultOptions[k] === undefined) {
        delete self.options[k];
      }
    });
  },
};

// @todo - replace this with exports from utils/sweetAlert2.js
window.swal2_wrapper = function(...argList) {
  let options = argList[0];
  if (typeof options === 'string') {
    options = {
      title: argList[0],
      text: argList[1],
      icon: argList[2],
    };
  }

  let type = options.type || options.icon,
      confirmButton = 'btn btn-danger';

  if (type === 'success') confirmButton = 'btn btn-primary';
  if (type === 'warning') confirmButton = 'btn btn-warning';

  let defaultOptions = {
    buttonsStyling: false,
    customClass: {
      container: `c-swal c-swal--${type}`,
      confirmButton,
      cancelButton: 'btn btn-naked',
    },
    focusConfirm: false,
  },
      notifications = null;

  if (options.notifications) {
    notifications = options.notifications;
    delete options.notifications;
  }

  if (options.type) {
    defaultOptions.icon = options.type; // v9 renames type to icon
    delete options.type;
  }
  options = $.extend(defaultOptions, options);

  if (options.toast === true) {
    delete options.focusConfirm;
  }

  if (notifications) {
    let notificationAlert = Swal.mixin(options);
    return notificationAlert.queue(notifications);
  }
  return base_js.swal.fire(options);
  // return Swal.fire(options);
};
